import React from 'react';

export interface IGetUserDetailsContext {
  getUserDetails: any;
  getUserDetailsData: any;
  getUserDetailsError: any;
  getUserDetailsLoading: any;
}

export default React.createContext<IGetUserDetailsContext>({
  getUserDetails: {},
  getUserDetailsData: {},
  getUserDetailsError: {},
  getUserDetailsLoading: {}
});
