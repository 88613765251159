import { RouteDefinition } from '@polestar/web3-core-react';
import getRuntimeConfig from '~config/index';

const config = getRuntimeConfig();

const PREFIX = `/:locale/${config.application.namespace}`;

/**
 * Array of route definition object
 * Each object will be map to react-router-dom <Route /> component and respect the following shape
 *
 * - name (string) : name of the route, internal reference used to generate a path for a given route
 * - path (string) : uri fragment, can contain variables in react-router-dom's fashion (e.g. /:locale/something/:id)
 * - exact (boolean) : wether to stricly match path or allow sub-routes (@see react-router-dom)
 * - view (string) : name of the view component to render
 * - private (boolean) : wether the route should be accessible publicly or not
 * - sitemap (object) : public sitemap.xml informations (if set to undefined, will be ignored by the sitemap and not indexed)
 */
export default <RouteDefinition[]>[
  {
    name: 'home',
    path: `${PREFIX}/`,
    exact: true,
    view: 'HomeView',
    private: false,
    sitemap: {
      frequency: 'WEEKLY',
      priority: 1
    }
  }
];
