export interface BuildParams {
  repositoryInfo?: {
    build: string;
    commit: string;
    tag: string;
  };
}

export function printBuildInfo({ repositoryInfo }: BuildParams) {
  if (!repositoryInfo) return;

  const buildInfo = Object.entries(repositoryInfo)
    .filter(([, val]) => {
      return !!val;
    })
    .map(([key, val]) => `${key}: ${val}`)
    .join(' ');

  console.info(buildInfo);
}
