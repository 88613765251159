import { useLazyQuery } from '@apollo/client';
import React, { useMemo } from 'react';
import GET_USER_DETAILS from '~app/apollo/queries/getUserDetails.gql';
import GetUserDetailsContext, { IGetUserDetailsContext } from './context';

export { GetUserDetailsContext };

const GetUserDetailsContextProvider = (props: any) => {
  const [getUserDetails, { data, loading, error }] = useLazyQuery(GET_USER_DETAILS, {
    errorPolicy: 'all',
    fetchPolicy: 'no-cache'
  });

  const providerValue: IGetUserDetailsContext = useMemo(
    () => ({
      getUserDetails,
      getUserDetailsData: data,
      getUserDetailsError: error,
      getUserDetailsLoading: loading
    }),
    [loading]
  );
  return <GetUserDetailsContext.Provider {...props} value={providerValue} />;
};

export default GetUserDetailsContextProvider;
