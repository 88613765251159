import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import getRuntimeConfig from '~config/index';
import { printBuildInfo, BuildParams } from '~src/utils';
import AppContextProvider from './contexts/AppContextProvider';
import apolloClient from './apollo/apollo-client';
import App from './App';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig.js';

const msalInstance = new PublicClientApplication(msalConfig);

const config = getRuntimeConfig();

printBuildInfo(config as BuildParams);

ReactDOM.render(
  <ApolloProvider client={apolloClient}>
    <MsalProvider instance={msalInstance}>
      <BrowserRouter>
        <AppContextProvider config={config}>
          <App />
        </AppContextProvider>
      </BrowserRouter>
    </MsalProvider>
  </ApolloProvider>,
  document.getElementById('root')
);
