import { ApolloClient, ApolloLink, split, HttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { getCookie } from '@polestar/web3-core-react';
import { InMemoryCache } from '@apollo/client/cache';
import getRuntimeConfig from '~config/index';

const runtimeConfig = getRuntimeConfig();

// check if we are to create the dato apollo client for the preview site or the regular site
let datoPreviewToken = '';
try {
  datoPreviewToken = new URLSearchParams(window.location.search).get('preview') || '';
} catch (error) {}

const defaultUrl = runtimeConfig.api[runtimeConfig.api.region];

const client = new ApolloClient({
  link: ApolloLink.from([
    new ApolloLink((operation, forward) => {
      operation.setContext(({ headers = {} }) => {
        let datoToken = localStorage.getItem('token');
        const polestarIdToken = getCookie('token').access_token;
        if (operation.getContext().dato === true) {
          datoToken = datoPreviewToken || runtimeConfig.dato.token;
        }
        const isDatoContext = operation.getContext().dato;
        const authToken = isDatoContext ? `Bearer ${datoToken}` : polestarIdToken;
        return {
          headers: {
            ...headers,
            ...(authToken && { authorization: authToken })
          },
          credentials: 'include'
        };
      });

      if (typeof window !== 'undefined') {
        operation.variables.origin = window.location.origin;
      }

      return forward(operation);
    }),
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) =>
          console.warn(
            `[GraphQL Error] Message: ${message}. Locations: ${locations}. Path: ${path}.`
          )
        );
      }
      if (networkError) {
        console.error(`[Network Error] ${networkError}`);
      }
    }),
    split(
      (operation) => operation.getContext().dato === true,
      new HttpLink({
        uri: datoPreviewToken
          ? <string>runtimeConfig.dato.previewUrl
          : <string>runtimeConfig.dato.baseUrl,
        useGETForQueries: false
      }),
      new HttpLink({
        uri: defaultUrl,
        useGETForQueries: false
      })
    )
  ]),
  cache: new InMemoryCache()
});

export default client;
