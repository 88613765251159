import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import loadable from '@loadable/component';
import { PolestarApp, PageContent } from '@polestar/component-warehouse-react';
import {
  ErrorBoundary,
  AppRouter,
  RouteDefinition,
  Themes,
  useMarket
} from '@polestar/web3-core-react';

import routes from '~routes/definition';

const LoadableView = loadable((props: { view: string }) => import(`./views/${props.view}/index`));

const LoadableRouter = () => {
  const getView = (routeDefinition: RouteDefinition | null, routeProps: RouteComponentProps) => (
    <LoadableView {...routeProps} view={routeDefinition?.view || 'NotFoundView'} />
  );
  const getNotFoundView = (
    _routeDefinition: RouteDefinition | null,
    routeProps: RouteComponentProps
  ) => <LoadableView {...routeProps} view="NotFoundView" />;

  return <AppRouter routes={routes} getView={getView} getNotFoundView={getNotFoundView} />;
};

const App = () => {
  const { error } = useMarket();

  if (error) {
    return <>Something went wrong.</>;
  }

  return (
    <ErrorBoundary>
      <PolestarApp rootSelector="#root" theme={Themes.light}>
        <PageContent>
          <LoadableRouter />
        </PageContent>
      </PolestarApp>
    </ErrorBoundary>
  );
};

export default App;
